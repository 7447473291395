import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';

import { Actions, jsonformsReducer } from '@jsonforms/core';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import App from './App';

const schema = {
  type: "object",
  properties: {
    "건폐율": {
      type: "number",
      description: "",
    },
    "전용률": {
      type: "number",
      description: "",
    },
    "최대지하층수": {
      type: "number",
      description: "",
    },
    "주차면적": {
      type: "number",
      description: "",
    },
    "세대당주차대수": {
      type: "number",
      description: "",
    },
    "최대용적률": {
      type: "number",
      description: "",
    },
    "최소노후도": {
      type: "number",
      description: "",
    },
    "최대공유인수": {
      type: "number",
      description: "",
    },
    "최소전용면적": {
      type: "number",
      description: "",
    },
    "최대전용면적": {
      type: "number",
      description: "",
    },
    "용도지역": {
      type: "string",
      description: "",
    },
  },
};

const store = createStore(
  combineReducers(
     { jsonforms: jsonformsReducer() }),  
// @ts-ignore
     {
       jsonforms: {
         cells: materialCells,
         renderers: materialRenderers,
       }
     }
);

interface Props {
  
}

interface State {
  data: any;
  output: string;
}

export default class extends Component<Props & RouteProps, State> {
  state = {
    data: {},
    output: "",
  };

  componentWillMount = () => {
    store.subscribe(this.onChange);

    store.dispatch(Actions.init({
      "건폐율": 60,
      "전용률": 50,
      "최대지하층수": 5,
      "주차면적": 30,
      "세대당주차대수": 0.5,
      "최대용적률": 800,
      "최소노후도": 20,
      "최대공유인수": 10,
      "최소전용면적": 19,
      "최대전용면적": 21,
      "용도지역": "일반상업지역",
    }, schema));
  }

  onChange = () => {
    this.setState({
      data: store.getState().jsonforms.core!.data,
    });
  }

  onSubmit = async () => {
    const payload = this.state.data;

    this.setState({
      output: `Submitting...\n${JSON.stringify(payload, null, 2)}`,
    });

    const r = await App.lambda.invoke({
      FunctionName: 'arn:aws:lambda:ap-northeast-2:331053433621:function:offiproject-public-platform-GetAllList-dev',
      Payload: JSON.stringify(payload),
    }).promise();

    this.setState({
      output: JSON.stringify(JSON.parse(r.Payload as string), null, 2),
    });

    const r2 = JSON.parse(r.Payload as string);

    window.location.href = r2.body.url;
  }

  render = () => {
    return (
      <Provider store={store}>
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '900px', marginTop: '100px' }}>
            <JsonForms />
            <input type="submit" style={{width: '100%', marginTop: '50px', height: '50px'}} onClick={this.onSubmit} />
            <pre style={{ marginTop: '30px', backgroundColor: 'grey', color: 'white' }}>
              {this.state.output}
            </pre>
          </div>
        </div>
      </Provider>
    )
  }
}
