import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

import AWS from 'aws-sdk';
import App from './App';

import camelcaseKeys from 'camelcase-keys';
import _ from 'lodash';

interface Props {
  
}

interface State {
  input: string;
  project?: any;
  result?: any[];
}

export default class extends Component<Props & RouteProps, State> {
  state = {
    input: "",
  } as State;

  componentWillMount = () => {
  }

  onSubmit = async () => {
    const p = await App.ddb.get({
      TableName: 'buildit-project',
      Key: {
        stage: 'prod',
        id: this.state.input,
      },
    }).promise();

    this.setState({
      project: camelcaseKeys(p.Item as any, {
        deep: true,
      }),
    });

    const r = await App.ddb.query({
      TableName: 'buildit-result',
      IndexName: 'stage-project_id-index',
      KeyConditionExpression: '#stage = :stage AND #project_id = :project_id',
      ExpressionAttributeNames: {
        '#stage': "stage",
        '#project_id': 'project_id',
      },
      ExpressionAttributeValues: {
        ':stage': "prod",
        ':project_id': this.state.input,
      },
    }).promise();


    this.setState({
      result: _(r.Items as any[]).sortBy(v => v.id).map(v => camelcaseKeys(v, { deep: true })).map((res: any) => {
        for (let k in res.resultViewer.templateList) {
          res.resultViewer.templateList[`${k.slice(0, 3).toUpperCase()}_${k.slice(3)}`] = res.resultViewer.templateList[k];
          delete res.resultViewer.templateList[k];
          console.log(k);
        }

        return res;
      }).value(),
    });
  }

  render = () => {
    return (
      <div>
        <div>
          <input type="text" value={this.state.input} onChange={(e) => this.setState({ input: e.target.value })} /><button onClick={this.onSubmit}>Export</button>
        </div>
        {
          this.state.project && (
            <div style={{border: '1px solid black'}}>
              <label>프로젝트</label>
              <textarea value={JSON.stringify(this.state.project, null, 2)} style={{width: '800px', height: '400px'}} />
            </div>
          )
        }
        
        {
          this.state.result && this.state.result.map((v, i) => (
            <div style={{border: '1px solid black'}}>
              <label>{i + 1}번 결과</label>
              <textarea value={JSON.stringify(v, null, 2)} style={{width: '800px', height: '400px'}} />
            </div>
          ))
        }
      </div>
    )
  }
}
