import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

import AWS from 'aws-sdk';

import wkx from 'wkx';

const { reproject } = require('reproject');

interface Props {
  
}

interface State {
  input: string;
  output: string;
}

export default class extends Component<Props & RouteProps, State> {
  state = {
    input: "",
    output: "",
  };

  componentWillMount = () => {
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let output = '';

    try {
      output = wkx.Geometry.parseGeoJSON(reproject(wkx.Geometry.parse(e.target.value).toGeoJSON(), '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs')).toWkt();
      // output = JSON.stringify(AWS.DynamoDB.Converter.unmarshall(JSON.parse(e.target.value)), null, 2);
    } catch (e) {

    }

    this.setState({
      input: e.target.value,
      output: output,
    });
  }

  render = () => {
    return (
      <div>
        <textarea value={this.state.input} onChange={this.onChange} style={{width: '800px', height: '400px'}}></textarea>
        <textarea value={this.state.output} style={{width: '800px', height: '400px'}}></textarea>
      </div>
    )
  }
}
