import React from 'react';

import './App.css';

import {Component} from 'react';
import {RouteProps} from 'react-router';

import AWS from 'aws-sdk';

import wkx from 'wkx';
import App from "./App";

const {reproject} = require('reproject');

interface Props {

}

interface State {
  code: string;
  data: string;
  output: string;
}

export default class extends Component<Props & RouteProps, State> {
  state = {
    code: `\
사업영역의 '용도지역'이 '제1종전용주거지역'이면 사업영역의 '용도지역'은 '전용주거지역'이다.
사업영역의 '용도지역'이 '제2종전용주거지역'이면 사업영역의 '용도지역'은 '전용주거지역'이다.
사업영역의 '용도지역'이 '제1종일반주거지역'이면 사업영역의 '용도지역'은 '일반주거지역'이다.
사업영역의 '용도지역'이 '제2종일반주거지역'이면 사업영역의 '용도지역'은 '일반주거지역'이다.
사업영역의 '용도지역'이 '제3종일반주거지역'이면 사업영역의 '용도지역'은 '일반주거지역'이다.

사업영역의 '용도지역'이 '전용주거지역'이면 사업영역의 '용도지역'은 '주거지역'이다.
사업영역의 '용도지역'이 '일반주거지역'이면 사업영역의 '용도지역'은 '주거지역'이다.
사업영역의 '용도지역'이 '준주거지역'이면 사업영역의 '용도지역'은 '주거지역'이다.

사업영역의 '용도지역'이 '중심상업지역'이면 사업영역의 '용도지역'은 '상업지역'이다.
사업영역의 '용도지역'이 '일반상업지역'이면 사업영역의 '용도지역'은 '상업지역'이다.
사업영역의 '용도지역'이 '근린상업지역'이면 사업영역의 '용도지역'은 '상업지역'이다.
사업영역의 '용도지역'이 '유통상업지역'이면 사업영역의 '용도지역'은 '상업지역'이다.

사업영역의 '용도지역'이 '주거지역'이면 사업영역의 '용도지역'은 '도시지역'이다.

사업영역의 '시도'가 '서울특별시'이고 사업영역의 '용도지역'이 '주거지역'이면 '법정건폐율'은 '60%'이고 '법정용적률'은 '500%'이다.
사업영역의 '시도'가 '서울특별시'이고 사업영역의 '용도지역'이 '상업지역'이면 '법정건폐율'은 '55%'이고 '법정용적률'은 '800%'이다.
사업영역의 '시도'가 '인천광역시'이고 사업영역의 '용도지역'이 '주거지역'이면 '법정건폐율'은 '50%'이고 '법정용적률'은 '450%'이다.
사업영역의 '시도'가 '인천광역시'이고 사업영역의 '용도지역'이 '상업지역'이면 '법정건폐율'은 '45%'이고 '법정용적률'은 '650%'이다.
`,
    data: `\
{
  "시도": "서울특별시",
  "용도지역": "제1종전용주거지역"
}
`,
    output: "",
  };

  async onClick() {
    try {
      const r = await App.lambda.invoke({
        FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:rnd-team-dsl-test-main-Handler",
        Payload: JSON.stringify({
          code: this.state.code,
          data: JSON.parse(this.state.data),
        }),
      }).promise();

      this.setState({
        output: r.Payload as string,
      });
    } catch (e) {
      this.setState({
        output: e.toString(),
      });
    }
  }

  componentWillMount = () => {
  }

  render = () => {
    return (
      <div>
        <div style={{width: '1200px', height: '400px'}}>
          코드:
          <textarea value={this.state.code} onChange={e => this.setState({code: e.target.value})}
                    style={{width: '100%', height: '90%'}}></textarea>
        </div>
        <div style={{width: '1200px', height: '200px'}}>
          데이터:
          <textarea value={this.state.data} onChange={e => this.setState({data: e.target.value})} style={{width: '100%', height: '90%'}}></textarea>
        </div>
        <div style={{width: '1200px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <button onClick={() => this.onClick()} style={{width: '100%', height: '50%'}}>실행</button>
        </div>
        <div style={{width: '1200px', height: '400px'}}>
          출력:
          <textarea value={this.state.output} style={{width: '100%', height: '90%'}}></textarea>
        </div>
      </div>
    )
  }
}
