import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

import queryString from 'querystring';

interface SigninProps {
  
}

export default class Signin extends Component<SigninProps & RouteProps> {
  render = () => {
    const qs = queryString.parse(this.props.location!.search.slice(1));

    sessionStorage.setItem("id_token", qs.id_token as string);
    console.log(sessionStorage.getItem('login_redirect_uri'));
    window.location.href = sessionStorage.getItem('login_redirect_uri') || '/';

    return (
      <div>
        Redirecting...
      </div>
    )
  }
}
