import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';

import { Actions, jsonformsReducer } from '@jsonforms/core';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import App from './App';

const schema = {
  type: "object",
  properties: {
    "Project ID": {
      type: "number",
    },
    "Result ID": {
      type: "number",
    },
  },
  required: ["Project ID", "Result ID"],
};

const store = createStore(
  combineReducers(
     { jsonforms: jsonformsReducer() }),  
// @ts-ignore
     {
       jsonforms: {
         cells: materialCells,
         renderers: materialRenderers,
       }
     }
);

interface Props {
  
}

interface State {
  data: any;
  output: string;
}

export default class extends Component<Props & RouteProps, State> {
  state = {
    data: {},
    output: "",
  };

  componentWillMount = () => {
    store.subscribe(this.onChange);

    store.dispatch(Actions.init({
    }, schema));
  }

  onChange = () => {
    this.setState({
      data: store.getState().jsonforms.core!.data,
    });
  }

  onSubmit = async () => {
    App.s3.getSignedUrl('getObject', {
      Bucket: "teneleven-engine-result",
      // @ts-ignore
      Key: `dev/${this.state.data['Project ID']}/${this.state.data['Result ID']}/locData/resLocation.json`,
      // @ts-ignore
      ResponseContentDisposition: `attachment; filename="${this.state.data['Project ID']}_${this.state.data['Result ID']}.json"`
    }, (e, r) => {
      if (e) {
        console.log(e); 
      } else {
        window.location.href = r;
      }
    })
    
    // const payload = {
    //   // @ts-ignore
    //   'pnus': this.state.data['필지목록'],
    //   // @ts-ignore
    //   'emails': this.state.data['담당자 이메일'],
    //   // @ts-ignore
    //   'floor_area_ratio': this.state.data['용적률'],
    //   // @ts-ignore
    //   'house_area': this.state.data['전용면적'],
    //   // @ts-ignore
    //   'before': this.state.data['역세권 청년주택 종상향 (변경전)'],
    //   // @ts-ignore
    //   'after': this.state.data['역세권 청년주택 종상향'],
    // };

    // this.setState({
    //   output: `Submitting...\n${JSON.stringify(payload, null, 2)}`,
    // });

    // const r = await App.lambda.invoke({
    //   FunctionName: 'arn:aws:lambda:ap-northeast-2:331053433621:function:platform-lambda-ss-ExecuteStepFunction',
    //   Payload: JSON.stringify({
    //     state_machine_arn: 'arn:aws:states:ap-northeast-2:331053433621:stateMachine:platform-buildit-states-OffiProject-r1-v20190816',
    //     input: payload,
    //   }),
    // }).promise();

    // this.setState({
    //   output: JSON.stringify(JSON.parse(r.Payload as string), null, 2),
    // });
  }

  render = () => {
    return (
      <Provider store={store}>
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '900px', marginTop: '100px' }}>
            <JsonForms />
            <input type="submit" style={{width: '100%', marginTop: '50px', height: '50px'}} onClick={this.onSubmit} />
            <pre style={{ marginTop: '30px', backgroundColor: 'grey', color: 'white' }}>
              {this.state.output}
            </pre>
          </div>
        </div>
      </Provider>
    )
  }
}
