import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

import AWS from 'aws-sdk';

import wkx from 'wkx';

import _ from 'lodash';

const { reproject } = require('reproject');

interface Props {
  
}

interface State {
  input: string;
  output: string;
}

export default class extends Component<Props & RouteProps, State> {
  state = {
    input: "",
    output: "",
  };

  componentWillMount = () => {
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let output = '';

    try {
      const data = (wkx.Geometry.parse(e.target.value).toGeoJSON() as any).coordinates[0];

      output = `${data.map((v: any) => `v ${v[0]} ${v[1]} 0.0`).join('\n')}\n${_.range(data.length - 1).map(v => `l ${v + 1} ${v + 2}`).join('\n')}`;

      // output = JSON.stringify(wkx.Geometry.parse(e.target.value).toGeoJSON());

      
      // output = JSON.stringify(AWS.DynamoDB.Converter.unmarshall(JSON.parse(e.target.value)), null, 2);
    } catch (e) {

    }

    this.setState({
      input: e.target.value,
      output: output,
    });
  }

  render = () => {
    return (
      <div>
        <textarea value={this.state.input} onChange={this.onChange} style={{width: '800px', height: '400px'}}></textarea>
        <textarea value={this.state.output} style={{width: '800px', height: '400px'}}></textarea>
      </div>
    )
  }
}
