import React, {useEffect, useState} from 'react';

import './App.css';
import App from "./App";
import wkx from "wkx";

const jsts = require('jsts');

const {reproject} = require('reproject');

async function get_new_project_id() {
    const stage = "dev";
    const email = "test-global-dev@1011.co.kr";

    const new_project_id = await App.ddb.update({
        TableName: 'platform-buildit-counter',
        Key: {'name': `${stage}/project_id`},
        ExpressionAttributeNames: {'#counter': 'counter'},
        ExpressionAttributeValues: {':counter': 1},
        UpdateExpression: 'SET #counter = #counter + :counter',
        ReturnValues: 'UPDATED_OLD',
    }).promise();

    const user_project_id = await App.ddb.update({
        TableName: 'platform-buildit-counter',
        Key: {'name': `${stage}/project_id/${email}`},
        ExpressionAttributeNames: {'#counter': 'counter'},
        ExpressionAttributeValues: {':counter': 1},
        UpdateExpression: 'SET #counter = #counter + :counter',
        ReturnValues: 'UPDATED_OLD',
    }).promise();

    return {pid: new_project_id.Attributes.counter, user_pid: user_project_id.Attributes.counter};
}

async function create_project(wkt) {
    const {pid, user_pid} = await get_new_project_id();

    wkt = wkt.replaceAll(/(\d+\.\d+) (\d+\.\d+)/g, (a, b, c) => {
        return `${Number(b)} ${Number(c)}`;
    });

    const original_wkt = wkt;

    console.log("WKT!!!", wkt);

    const reader = new jsts.io.WKTReader();

    // const centroid = reader.read(wkt).getCentroid();
    //
    // console.log("CENTROID", centroid.getX(), centroid.getY());
    //
    // const korea_x = 198233.14334679052;
    // const korea_y = 552273.4502401776;
    //
    // wkt = wkt.replaceAll(/(\d+\.\d+) (\d+\.\d+)/g, (a, b, c) => {
    //     return `${Number(b) - centroid.getX() + korea_x} ${Number(c) - centroid.getY() + korea_y}`;
    // });

    await App.ddb.put({
        TableName: "platform-buildit-project",
        Item: {
            "stage": "dev",
            "email": "test-global-dev@1011.co.kr",
            "project_id": pid,
            "user_project_id": user_pid,
            "status": "WAITING",

            "auto_name": true,
            "project_name": `글로벌 테스트`,
            "auto_setting": true,
            "project_use_district": "제3종일반주거지역",

            // "project_explanation": original_wkt,

            "customer": {
               'site': 'global',
            },

            "view_type_sky": 25,
            "view_type_landmark": 25,
            "view_type_green": 25,
            "view_type_river": 25,

            "daylight_hours_demanded_avg": 2,
            "daylight_hours_proportion_less_avg": 50,
            "multi_select_project_site": false,
            "road_site": [],
            "skyline_circle": [],

            "road_value": [],

            "vacancy_outside_area": 0,
            "vacancy_outside": [],
            "vacancy_inside_area": 0,
            "vacancy_inside": [],

            "created_at": new Date().toISOString(),
            "run_at": new Date().toISOString(),

            "project_type": "AI",
            "project_site_area": reader.read(wkt).getArea(),
            "project_site_type": "DRAW",
            "project_site": [
                wkt,
            ],
            "project_site_center": reader.read(wkt).getCentroid().toText(),
            "boundary_site": [
                reader.read(wkt).buffer(4).toText(),
            ],

            "field_info_pnu": [],
            "jimog": [],

            "building_type": "아파트",
            "building_stories_avg_type": "NUMERICAL",

            "discarded": false,
            "deleted": false,
            "favorite": false,
            "discountable": false,

            "reports_number": 20,

            "my_building_shape_type": [],
            "building_shape_type": [],
            "skyline_line": [],

            "housing_plan_type": [
                {
                    "area": 74,
                    "proportion": 100,
                    "bay": 3
                }
            ],
            "floor_height": 3.0,
            "default_building_land_ratio": 60,
            "building_land_ratio": 50,
            "default_floor_area_ratio": 250,
            "floor_area_ratio": 250,

            "distance_between_side_walls": 4,
            "distance_between_side_opaque_walls": 8,
            "distance_between_window_opaque_walls": 0.8,

            "setback_building_line_city_apartment": 3,
            "setback_building_line_city_multi_house": 3,
            "setback_building_line_city_row_house": 3,
            "setback_building_line_apartment": 3,
            "setback_building_line_multi_house": 3,
            "setback_building_line_officetel": 3,
            "setback_building_line_row_house": 3,

            "setback_site_boundary_city_apartment": 3,
            "setback_site_boundary_city_row_house": 3,
            "setback_site_boundary_city_multi_house": 3,
            "setback_site_boundary_apartment": 3,
            "setback_site_boundary_multi_house": 3,
            "setback_site_boundary_officetel": 3,
            "setback_site_boundary_row_house": 3,

            "setback_regulation_from_site_boundary": 0.5,
            "setback_regulation_from_north_less_9m_type": "METER",
            "setback_regulation_from_north_less_9m": 1.5,
            "setback_regulation_from_north_more_9m_type": "HEIGHT",
            "setback_regulation_from_north_more_9m": 0.5,

            "building_stories_avg": 20,
            "building_stories_min": 18,
            "building_stories_max": 25,

            "housing_rate": 100,
        },
    }).promise();

    const r = await App.lambda.invoke({
        FunctionName: `engine-buildit-run-project-dev`,
        Payload: JSON.stringify({
            stage: "dev",
            project_id: pid,
            user_id: "f3d38fbc-3212-4669-9d7c-c40b3b70e48d",
            type: "point",
            amount: 0,
        })
    }).promise();
}

function LLtoTM(x, y) {
    const output = wkx.Geometry.parseGeoJSON(reproject(wkx.Geometry.parse(`POINT(${x} ${y})`).toGeoJSON(), '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs', '+proj=utm +zone=47 +datum=WGS84 +units=m +no_defs')).toWkt();

    const [_2, cx, cy] = output.match(/^POINT\((\S+) (\S+)\)$/);

    return [Number(cx), Number(cy)];
}

function GlobalServiceGoogleMaps() {
    const google = window.google;

    let [map, setMap] = useState();

    useEffect(() => {
        map = new google.maps.Map(document.getElementById('map'), {
            center: {lat: 1.28, lng: 103.85},
            zoom: 14
        });

        setMap(map);

        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.POLYGON,
                ],
            },
        });

        drawingManager.setMap(map);

        google.maps.event.addListener(drawingManager, "overlaycomplete", async function (event) {
            console.log(event.overlay.getPath().getArray());

            const pts = event.overlay.getPath().getArray();

            const tm_pts = pts.map(p => {
                return LLtoTM(p.lng(), p.lat());
            });

            tm_pts.push(tm_pts[0]);

            const wkt = `POLYGON((${tm_pts.map(p => `${p[0]} ${p[1]}`).join(', ')}))`;

            await create_project(wkt);

            event.overlay.setMap(null);
        });
    }, []);

    return (
        <div id={"map"} style={{"width": "100vw", "height": "100vh"}}>
        </div>
    );
}

export default GlobalServiceGoogleMaps;
