import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

interface Props {
  
}

interface State {
}

export default class extends Component<Props & RouteProps, State> {
  render = () => {
    return (
      <div>
        <ul>
          <li><a href="/offiproject">오피스텔 프로젝트 생성</a></li>
          <li><a href="/offiproject-emailer">오피스텔 프로젝트 보고서 출력</a></li>
          <li><a href="/dynamodb-json-converter">DynamoDB JSON 변환기</a></li>
          <li><a href="/buildit-project-json">빌드잇 프로젝트 JSON 추출기</a></li>
          <li><a href="/tm-to-ll-converter">TM to LL Converter</a></li>
          <li><a href="/ll-to-to-converter">LL to TM Converter</a></li>
          <li><a href="/wkt-to-obj">WKT to OBJ</a></li>
          <li><a href="/download-bim">BIM Download</a></li>
          <li><a href="/offiproject-excel">오피스텔 프로젝트 엑셀</a></li>
          <li><a href="/offiproject-docx">오피스텔 프로젝트 워드</a></li>
          <li><a href="/youthhousing-report">역세권 보고서 워드</a></li>
          <li><a href="/reslt-json-download">Result, resLocation 다운로드</a></li>
          <li><a href="/jomang-dataset">조망점수 데이터셋</a></li>
          <li><a href="/jomang-result">조망점수 학습 결과</a></li>
          <li><a href="/cesium-viewer">CesiumJS 뷰어</a></li>
          <li><a href="/global-service-test">Global Service Test</a></li>
          <li><a href="/global-service-google-maps">Global Service Google Maps</a></li>
          <li><a href="/lhmap-compute-report-score">LHMAP 평가표 점수 디버깅</a></li>
          <li><a href="/dsl-test">DSL Test</a></li>
        </ul>
      </div>
    )
  }
}
