import React, {useEffect, useState} from 'react';

import './App.css';
import App from "./App";
import wkx from "wkx";

const {reproject} = require('reproject');

const JSZip = require("jszip");

function TMtoLL(x, y) {
    const output = wkx.Geometry.parseGeoJSON(reproject(wkx.Geometry.parse(`POINT(${x} ${y})`).toGeoJSON(), '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs')).toWkt();

    const [_2, cx, cy] = output.match(/^POINT\((\S+) (\S+)\)$/);

    return [Number(cx), Number(cy)];
}

function WKTtoPolygon(wkt) {
    const geom = wkx.Geometry.parse(wkt).toGeoJSON();

    return geom.coordinates[0].map(([x, y]) => {
        return TMtoLL(x, y);
    }).flat();
}

function CesiumViewer() {
    const Cesium = window.Cesium;

    const [projectId, setProjectId] = useState();
    const [viewer, setViewer] = useState();
    const [tilesetId, setTilesetId] = useState(0);
    const [currentTileset, setCurrentTileset] = useState();
    const [buildingFilter, setBuildingFilter] = useState({})
    const [buildingFilterEnabled, setBuildingFilterEnabled] = useState(false);
    const [models, setModels] = useState([]);

    useEffect(() => {
        Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkZGJiNzlmNC01Zjg1LTRkOGQtOTIyNS0xZWRmNGY5MDc2NjIiLCJpZCI6NTY5NDMsImlhdCI6MTYyMTkyMTAzM30.1iyi0dNSkv8VrFHD7LvOQY1zrJJR8x22KEFrqv9fyBw';

        const viewer = new Cesium.Viewer('cesiumContainer', {
            terrainProvider: new Cesium.EllipsoidTerrainProvider(),
        });
        // Add Cesium OSM Buildings, a global 3D buildings layer.
        // const buildingTileset = viewer.scene.primitives.add(Cesium.createOsmBuildings());

        setViewer(viewer);

        // Fly the camera to San Francisco at the given longitude, latitude, and height.
        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(126.88966127334376, 37.57049210905622, 700),
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-35.0),
            }
        });
    }, []);

    useEffect(() => {
        if (viewer) {
            viewer.scene.primitives.remove(currentTileset);

            if (tilesetId === 0) { // 지리원
                const tileset = new Cesium.Cesium3DTileset({
                    url: Cesium.IonResource.fromAssetId(486892),
                });

                viewer.scene.primitives.add(tileset);
                setCurrentTileset(tileset);
            } else { // 3ds
                const tileset = new Cesium.Cesium3DTileset({
                    url: Cesium.IonResource.fromAssetId(473687),
                });

                viewer.scene.primitives.add(tileset);
                setCurrentTileset(tileset);
            }
        }
    }, [viewer, tilesetId]);

    useEffect(() => {
        if (viewer) {
            for (let model of models) {
                model.show = buildingFilterEnabled;
            }

            if (tilesetId === 0) { // 지리원
                if (buildingFilterEnabled) {
                    currentTileset.style = new Cesium.Cesium3DTileStyle({
                        show: {
                            conditions: [
                                ...(buildingFilter.buildings_지리원 || []).map(x => [`\${feature['id']} === '${x.id}'`, false]),
                                [true, true],
                            ]
                        },
                    });
                } else {
                    currentTileset.style = new Cesium.Cesium3DTileStyle({
                        color: {
                            conditions: [
                                ...(buildingFilter.buildings_지리원 || []).map(x => [`\${feature['id']} === '${x.id}'`, "color('red')"]),
                                [true, "color('white')"],
                            ]
                        },
                    });
                }
            } else { // 3ds
                if (buildingFilterEnabled) {
                    currentTileset.style = new Cesium.Cesium3DTileStyle({
                        show: {
                            conditions: [
                                ...(buildingFilter.buildings_3ds || []).map(x => [`\${feature['Name']} === '${x.id.replace('3ds/', '')}'`, false]),
                                [true, true],
                            ]
                        },
                    });
                } else {
                    currentTileset.style = new Cesium.Cesium3DTileStyle({
                        color: {
                            conditions: [
                                ...(buildingFilter.buildings_3ds || []).map(x => [`\${feature['Name']} === '${x.id.replace('3ds/', '')}'`, "color('red')"]),
                                [true, "color('white')"],
                            ]
                        },
                    });
                }
            }
        }
    }, [currentTileset, buildingFilter, buildingFilterEnabled]);

    async function loadProject(projectId) {
        const r = await App.ddb.get({
            TableName: "platform-buildit-project",
            Key: {
                "stage": "dev",
                "project_id": parseInt(projectId),
            }
        }).promise();

        const [_1, x, y] = r.Item.project_site_center.match(/^POINT\((\S+) (\S+)\)$/);

        const [cx, cy] = TMtoLL(x, y - 700);

        const buildingFilter = {
            "buildings_3ds": [],
            "buildings_지리원": [],
        };

        async function addBuildingFilter(wkt) {
            const rr = await App.lambda.invoke({
                FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:rnd-team-stack-dev-CesiumBuildingQuery",
                Payload: JSON.stringify({
                    query: wkt,
                })
            }).promise();

            const rrr = JSON.parse(rr.Payload);

            buildingFilter.buildings_3ds = [...buildingFilter.buildings_3ds, ...rrr.buildings_3ds];
            buildingFilter.buildings_지리원 = [...buildingFilter.buildings_지리원, ...rrr.buildings_지리원];
        }

        viewer.entities.removeAll();

        viewer.entities.add({
            polygon: {
                hierarchy: Cesium.Cartesian3.fromDegreesArray(WKTtoPolygon(r.Item.project_site[0])),
                height: 0.1,
                material: Cesium.Color.BLUE.withAlpha(0.5),
                outline: true,
                outlineColor: Cesium.Color.BLACK
            }
        });

        await addBuildingFilter(r.Item.project_site[0]);

        for (let wkt of (r.Item.vacancy_inside || [])) {
            viewer.entities.add({
                polygon: {
                    hierarchy: Cesium.Cartesian3.fromDegreesArray(WKTtoPolygon(wkt)),
                    height: 0.1,
                    material: Cesium.Color.RED.withAlpha(0.5),
                    outline: true,
                    outlineColor: Cesium.Color.BLACK
                }
            });

            await addBuildingFilter(wkt);
        }

        for (let wkt of (r.Item.vacancy_outside || [])) {
            viewer.entities.add({
                polygon: {
                    hierarchy: Cesium.Cartesian3.fromDegreesArray(WKTtoPolygon(wkt)),
                    height: 0.1,
                    material: Cesium.Color.GREEN.withAlpha(0.5),
                    outline: true,
                    outlineColor: Cesium.Color.BLACK
                }
            });

            await addBuildingFilter(wkt);
        }

        setBuildingFilter(buildingFilter);

        for (let model of models) {
            viewer.scene.primitives.remove(model);
        }

        const newModels = [];

        try {
            let reportId = 20;

            if (Number(projectId) === 23239) {
                reportId = 1;
            }

            const objs = await App.s3.listObjectsV2({
                Bucket: "teneleven-engine-result",
                Prefix: `dev/${projectId}/${reportId}/resultObjData/`,
            }).promise();

            for (let x of objs.Contents.filter(x => x.Key.endsWith(".glb"))) {
                const url = App.s3.getSignedUrl("getObject", {
                    Bucket: "teneleven-engine-result",
                    Key: x.Key,
                });

                const {ccx, ccy} = JSON.parse((await App.s3.getObject({
                    Bucket: "teneleven-engine-result",
                    Key: x.Key + ".json",
                }).promise()).Body);

                console.log(ccx, ccy, url);

                const origin = Cesium.Cartesian3.fromDegrees(ccx, ccy, 0);
                const modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(origin);

                const model = viewer.scene.primitives.add(
                    Cesium.Model.fromGltf({
                        url,
                        modelMatrix,
                        color: Cesium.Color.LIGHTBLUE,
                        show: buildingFilterEnabled,
                    })
                )

                newModels.push(model);
            }
        } catch (e) {

        }

        setModels(newModels);

        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(cx, cy, 700),
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-45.0),
            }
        });
    }

    return (
        <div style={{"display": "flex", "justifyContent": "Center", "alignItems": "center"}}>
            <div>
                <div>
                    <input style={{"margin": "5px"}} value={projectId} placeholder={"Project ID"} type={"text"}
                           onChange={e => setProjectId(e.target.value)}
                           onKeyDown={e => {
                               if (e.keyCode === 13) {
                                   loadProject(projectId);
                               }
                           }}/>
                    <button style={{"margin": "5px"}} onClick={_ => loadProject(projectId)}>Load Project</button>
                    <input
                        style={{"margin": "5px"}}
                        type={"radio"}
                        name={"tileset"}
                        checked={tilesetId === 0}
                        onChange={e => {
                            if (e.target.value) {
                                setTilesetId(0);
                            }
                        }}
                    />
                    <label>지리원 지붕도</label>
                    <input
                        style={{"margin": "5px"}}
                        type={"radio"}
                        name={"tileset"}
                        checked={tilesetId === 1}
                        onChange={e => {
                            if (e.target.value) {
                                setTilesetId(1);
                            }
                        }}
                    />
                    <label>국토부 3ds</label>
                    <input
                        style={{"margin": "5px"}}
                        type={"checkbox"}
                        name={"show_result"}
                        checked={buildingFilterEnabled}
                        onChange={e => {
                            if (e.target.checked) {
                                setBuildingFilterEnabled(true);
                            } else {
                                setBuildingFilterEnabled(false);
                            }
                        }}
                    />
                    <label>빌드잇 결과 보이기</label>
                </div>
                <div id={"cesiumContainer"} style={{width: '90vw', height: '90vh'}}>

                </div>
            </div>
        </div>
    )
}

export default CesiumViewer;
