import React, {useEffect, useState} from 'react';

import './App.css';
import App from "./App";
import wkx from "wkx";

const {reproject} = require('reproject');

const jsts = require('jsts');

const VISIBLE_REPORT_ID = 27;

function TMtoLL(x, y) {
    const output = wkx.Geometry.parseGeoJSON(reproject(wkx.Geometry.parse(`POINT(${x} ${y})`).toGeoJSON(), '+proj=utm +zone=47 +datum=WGS84 +units=m +no_defs', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs')).toWkt();

    const [_2, cx, cy] = output.match(/^POINT\((\S+) (\S+)\)$/);

    return [Number(cx), Number(cy)];
}

function LLtoTM(x, y) {
    const output = wkx.Geometry.parseGeoJSON(reproject(wkx.Geometry.parse(`POINT(${x} ${y})`).toGeoJSON(), '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs', '+proj=utm +zone=47 +datum=WGS84 +units=m +no_defs')).toWkt();

    const [_2, cx, cy] = output.match(/^POINT\((\S+) (\S+)\)$/);

    return [Number(cx), Number(cy)];
}

function WKTtoPolygon(wkt) {
    const geom = wkx.Geometry.parse(wkt).toGeoJSON();

    return geom.coordinates[0].map(([x, y]) => {
        return TMtoLL(x, y);
    }).flat();
}

async function get_new_project_id() {
    const stage = "dev";
    const email = "test-global-dev@1011.co.kr";

    const new_project_id = await App.ddb.update({
        TableName: 'platform-buildit-counter',
        Key: {'name': `${stage}/project_id`},
        ExpressionAttributeNames: {'#counter': 'counter'},
        ExpressionAttributeValues: {':counter': 1},
        UpdateExpression: 'SET #counter = #counter + :counter',
        ReturnValues: 'UPDATED_OLD',
    }).promise();

    const user_project_id = await App.ddb.update({
        TableName: 'platform-buildit-counter',
        Key: {'name': `${stage}/project_id/${email}`},
        ExpressionAttributeNames: {'#counter': 'counter'},
        ExpressionAttributeValues: {':counter': 1},
        UpdateExpression: 'SET #counter = #counter + :counter',
        ReturnValues: 'UPDATED_OLD',
    }).promise();

    return {pid: new_project_id.Attributes.counter, user_pid: user_project_id.Attributes.counter};
}

async function create_project(wkt) {
    const {pid, user_pid} = await get_new_project_id();

    wkt = wkt.replaceAll(/(\d+\.\d+) (\d+\.\d+)/g, (a, b, c) => {
        return `${Number(b)} ${Number(c)}`;
    })

    console.log("WKT!!!", wkt);

    const reader = new jsts.io.WKTReader();

    await App.ddb.put({
        TableName: "platform-buildit-project",
        Item: {
            "stage": "dev",
            "email": "test-global-dev@1011.co.kr",
            "project_id": pid,
            "user_project_id": user_pid,
            "status": "WAITING",

            "auto_name": true,
            "project_name": `싱가폴 테스트`,
            "auto_setting": true,
            "project_use_district": "제3종일반주거지역",

            "view_type_sky": 25,
            "view_type_landmark": 25,
            "view_type_green": 25,
            "view_type_river": 25,

            "daylight_hours_demanded_avg": 2,
            "daylight_hours_proportion_less_avg": 50,
            "multi_select_project_site": false,
            "road_site": [],
            "skyline_circle": [],

            "road_value": [],

            "vacancy_outside_area": 0,
            "vacancy_outside": [],
            "vacancy_inside_area": 0,
            "vacancy_inside": [],

            "created_at": new Date().toISOString(),
            "run_at": new Date().toISOString(),

            "project_type": "AI",
            "project_site_area": reader.read(wkt).getArea(),
            "project_site": [
                wkt,
            ],
            "project_site_center": reader.read(wkt).getCentroid().toText(),
            "boundary_site": [
                reader.read(wkt).buffer(4).toText(),
            ],

            "field_info_pnu": [],
            "jimog": [],

            "building_type": "아파트",
            "building_stories_avg_type": "NUMERICAL",

            "discarded": false,
            "deleted": false,
            "favorite": false,
            "discountable": false,

            "reports_number": 20,

            "my_building_shape_type": [],
            "building_shape_type": [],
            "skyline_line": [],

            "housing_plan_type": [
                {
                    "area": 74,
                    "proportion": 100,
                    "bay": 3
                }
            ],
            "floor_height": 3.0,
            "default_building_land_ratio": 60,
            "building_land_ratio": 50,
            "default_floor_area_ratio": 250,
            "floor_area_ratio": 250,

            "distance_between_side_walls": 4,
            "distance_between_side_opaque_walls": 8,
            "distance_between_window_opaque_walls": 0.8,

            "setback_building_line_city_apartment": 3,
            "setback_building_line_city_multi_house": 3,
            "setback_building_line_city_row_house": 3,
            "setback_building_line_apartment": 3,
            "setback_building_line_multi_house": 3,
            "setback_building_line_officetel": 3,
            "setback_building_line_row_house": 3,

            "setback_site_boundary_city_apartment": 3,
            "setback_site_boundary_city_row_house": 3,
            "setback_site_boundary_city_multi_house": 3,
            "setback_site_boundary_apartment": 3,
            "setback_site_boundary_multi_house": 3,
            "setback_site_boundary_officetel": 3,
            "setback_site_boundary_row_house": 3,

            "setback_regulation_from_site_boundary": 0.5,
            "setback_regulation_from_north_less_9m_type": "METER",
            "setback_regulation_from_north_less_9m": 1.5,
            "setback_regulation_from_north_more_9m_type": "HEIGHT",
            "setback_regulation_from_north_more_9m": 0.5,

            "building_stories_avg": 20,
            "building_stories_min": 18,
            "building_stories_max": 25,

            "housing_rate": 100,
        },
    }).promise();

    const r = await App.lambda.invoke({
        FunctionName: `engine-buildit-run-project-dev`,
        Payload: JSON.stringify({
            stage: "dev",
            project_id: pid,
            user_id: "f3d38fbc-3212-4669-9d7c-c40b3b70e48d",
            type: "point",
            amount: 0,
        })
    }).promise();
}

function GlobalServiceTest() {
    const Cesium = window.Cesium;

    const [viewer, setViewer] = useState();

    const [buildingTilesets, setBuildingTilesets] = useState();
    const [visibleProjects, setVisibleProjects] = useState([]);
    const [projectViewData, setProjectViewData] = useState({});

    const [projectList, setProjectList] = useState([]);

    async function updateProjectList() {
        const r = await App.lambda.invoke({
            FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:rnd-team-stack-dev-EsProxy",
            Payload: JSON.stringify({
                table: "platform-buildit-project",
                body: {
                    query: {
                        query_string: {
                            query: `stage.keyword:"dev" AND email.keyword:"test-global-dev@1011.co.kr" AND discarded:false AND deleted:false`,
                        },
                    },
                    sort: [{"project_id": "desc"}],
                },
            }),
        }).promise();

        const rr = JSON.parse(r.Payload);

        // console.log("PROJECTLIST", rr.hits.hits);

        setProjectList(rr.hits.hits.map(x => x._source));
    }

    useEffect(() => {
        updateProjectList();
    }, []);

    useEffect(() => {
        if (!viewer) {
            return;
        }

        (async () => {
            for (let pid of visibleProjects) {
                if (pid in projectViewData) {
                    continue;
                }

                const projectJson = await App.ddb.get({
                    TableName: "platform-buildit-project",
                    Key: {
                        stage: "dev",
                        project_id: pid,
                    },
                }).promise();

                console.log(projectJson.Item);

                const project_site = projectJson
                    .Item
                    .project_site[0]
                    .replaceAll(/(\d+\.\d+) (\d+\.\d+)/g, (a, b, c) => {
                        return `${Number(b) - 170000} ${Number(c) - 500000}`;
                    });

                const polygon = WKTtoPolygon(project_site);

                const floorPolygon = viewer.entities.add({
                    polygon: {
                        hierarchy: Cesium.Cartesian3.fromDegreesArray(polygon),
                        height: 0.1,
                        material: Cesium.Color.BLUE.withAlpha(0.5),
                        outline: true,
                        outlineColor: Cesium.Color.BLACK
                    }
                });

                const buildings = JSON.parse((await App.lambda.invoke({
                    FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:rnd-team-stack-dev-CesiumBuildingQueryGlobal",
                    Payload: JSON.stringify({
                        query: project_site,
                    }),
                }).promise()).Payload).buildings;

                console.log("BUILDINGS", buildings);

                const objs = await App.s3.listObjectsV2({
                    Bucket: "teneleven-engine-result",
                    Prefix: `dev/${pid}/${VISIBLE_REPORT_ID}/resultObjData/`,
                }).promise();

                const models = [];

                console.log("OBJS", objs.Contents);

                await Promise.all(objs.Contents.filter(x => x.Key.endsWith(".glb")).map(async x => {
                    const url = App.s3.getSignedUrl("getObject", {
                        Bucket: "teneleven-engine-result",
                        Key: x.Key,
                    });

                    const {ccx, ccy} = JSON.parse((await App.s3.getObject({
                        Bucket: "teneleven-engine-result",
                        Key: x.Key + ".json",
                    }).promise()).Body);

                    console.log(ccx, ccy, url);

                    const origin = Cesium.Cartesian3.fromDegrees(ccx, ccy, 0);
                    const modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(origin);

                    const model = viewer.scene.primitives.add(
                        Cesium.Model.fromGltf({
                            url,
                            modelMatrix,
                            color: Cesium.Color.LIGHTBLUE,
                            show: true,
                        })
                    )

                    models.push(model);
                }))

                projectViewData[pid] = {
                    floorPolygon,
                    buildings,
                    models,
                    visible: true,
                }
            }

            setProjectViewData({
                ...projectViewData,
            });
        })();

    }, [visibleProjects, viewer]);

    useEffect(() => {
        if (!viewer || !buildingTilesets) {
            return;
        }

        console.log("VIEWDATA", projectViewData);

        let conds = [];

        for (let pid in projectViewData) {
            conds.push(...projectViewData[pid].buildings.map(x => [`\${feature['id']} === '${x.id}'`, false]));
        }

        conds.push([true, true]);

        for (const buildingTileset of buildingTilesets) {
            buildingTileset.style = new Cesium.Cesium3DTileStyle({
                show: {
                    conditions: conds,
                }
            });
        }
    }, [projectViewData, viewer]);

    useEffect(() => {
        Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkZGJiNzlmNC01Zjg1LTRkOGQtOTIyNS0xZWRmNGY5MDc2NjIiLCJpZCI6NTY5NDMsImlhdCI6MTYyMTkyMTAzM30.1iyi0dNSkv8VrFHD7LvOQY1zrJJR8x22KEFrqv9fyBw';

        const viewer = new Cesium.Viewer("cesiumContainer", {
            terrainProvider: new Cesium.CesiumTerrainProvider({
                url: Cesium.IonResource.fromAssetId(601416),
            }),
            imageryProvider: new Cesium.IonImageryProvider({assetId: 4}),
        });

        // Add Cesium OSM Buildings, a global 3D buildings layer.
        // const buildingTileset = viewer.scene.primitives.add(Cesium.createOsmBuildings());

        const buildingTilesets = [];

        for (const id of [635935, 635934, 635933]) {
            buildingTilesets.push(viewer.scene.primitives.add(
                new Cesium.Cesium3DTileset({
                    url: Cesium.IonResource.fromAssetId(id),
                })
            ));
        }

        setViewer(viewer);
        setBuildingTilesets(buildingTilesets);

        const IMAGERY_ASSETS = [
            609182,
            609183,
            609184,
            609185,
            609186,
            609187,
            609188,
            609189,
            609191,
            609192,
            609193,
            609194,
            609195,
            609196,
            609197,
            609198,
            609199,
            609200,
            609203,
            609204,
            609205,
            609206,
            609253,
            610201,
            610202,
        ];

        for (let assetId of IMAGERY_ASSETS) {
            viewer.imageryLayers.addImageryProvider(
                new Cesium.IonImageryProvider({assetId})
            );
        }

        const globe = viewer.scene.globe;

        // Fly the camera to San Francisco at the given longitude, latitude, and height.
        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(103.8638469, 1.2867286, 2500),
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-90.0),
            }
        });

        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK,
        );

        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
        );

        function createPoint(worldPosition) {
            return viewer.entities.add({
                position: worldPosition,
                point: {
                    color: Cesium.Color.BLACK,
                    pixelSize: 5,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                },
            });
        }

        function drawShape(positionData) {
            return viewer.entities.add({
                polygon: {
                    hierarchy: positionData,
                    outline: true,
                    outlineWidth: 20,
                    material: new Cesium.ColorMaterialProperty(
                        Cesium.Color.BLACK.withAlpha(0.5)
                    ),
                },
            });
        }

        let activeShapePoints = [];
        let activeShape;
        let floatingPoint;
        let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

        handler.setInputAction(function (event) {
            // We use `viewer.scene.pickPosition` here instead of `viewer.camera.pickEllipsoid` so that
            // we get the correct point when mousing over terrain.
            const earthPosition = viewer.scene.pickPosition(event.position);
            // `earthPosition` will be undefined if our mouse is not over the globe.
            if (Cesium.defined(earthPosition)) {

                if (activeShapePoints.length === 0) {
                    floatingPoint = createPoint(earthPosition);
                    activeShapePoints.push(earthPosition);
                    const dynamicPositions = new Cesium.CallbackProperty(function () {
                        return new Cesium.PolygonHierarchy(activeShapePoints);
                    }, false);
                    activeShape = drawShape(dynamicPositions);
                }

                activeShapePoints.push(earthPosition);
                createPoint(earthPosition);
            }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

        handler.setInputAction(function (event) {
            if (Cesium.defined(floatingPoint)) {
                const newPosition = viewer.scene.pickPosition(event.endPosition);
                if (Cesium.defined(newPosition)) {
                    floatingPoint.position.setValue(newPosition);
                    activeShapePoints.pop();
                    activeShapePoints.push(newPosition);
                }
            }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        function toDegrees(cartesian3Pos) {
            let pos = Cesium.Cartographic.fromCartesian(cartesian3Pos)
            return [pos.longitude / Math.PI * 180, pos.latitude / Math.PI * 180]
        }

        function terminateShape() {
            activeShapePoints.pop();
            drawShape(activeShapePoints);

            console.log("POINTS", activeShapePoints);
            console.log("COORDS", activeShapePoints.map(x => {
                const [px, py] = toDegrees(x);

                return LLtoTM(px, py);
            }));

            const coords = activeShapePoints.map(x => {
                const [px, py] = toDegrees(x);

                return LLtoTM(px, py);
            });

            coords.push(coords[0]);

            const wkt = "POLYGON ((" + coords.map(([x, y]) => {
                return `${x} ${y}`;
            }).join(',') + "))";

            console.log(wkt);

            create_project(wkt);

            App.lambda.invoke({
                FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:rnd-team-stack-dev-CesiumBuildingQueryGlobal",
                Payload: JSON.stringify({
                    query: wkt,
                }),
            }).promise().then((r) => {
                const rr = JSON.parse(r.Payload);

                for (const buildingTileset of buildingTilesets) {
                    buildingTileset.style = new Cesium.Cesium3DTileStyle({
                        show: {
                            conditions: [
                                ...(rr.buildings || []).map(x => [`\${feature['id']} === '${x.id}'`, false]),
                                [true, true],
                            ]
                        },
                    });
                }
            })

            viewer.entities.remove(floatingPoint);
            viewer.entities.remove(activeShape);
            floatingPoint = undefined;
            activeShape = undefined;
            activeShapePoints = [];

            updateProjectList();
        }

        handler.setInputAction(function (event) {
            terminateShape();
        }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    }, []);

    return (
        <div style={{"display": "flex", "flexDirection": "row"}}>
            <div style={{"width": "300px", "height": "100vh", "overflowY": "scroll"}}>
                <div>
                    <button>사업영역 분석</button>
                </div>
                <div>
                    {
                        projectList.map(x => {
                            return (
                                <div style={{"width": "100%", "borderTop": "1px solid", "borderBottom": "1px solid"}}>
                                    <div>
                                        {
                                            x.status === "FINISHED" && (
                                                <img style={{"width": "100%"}} src={App.s3.getSignedUrl("getObject", {
                                                    Bucket: "teneleven-engine-result",
                                                    Key: `${x.stage}/${x.project_id}/${VISIBLE_REPORT_ID}/resImage/${x.project_id}_${VISIBLE_REPORT_ID}_image.png`
                                                })}/>
                                            )
                                        }
                                    </div>
                                    <div key={x.project_id} style={{
                                        "display": "flex",
                                        "justifyContent": "space-around",
                                        "width": "100%",
                                    }}>
                                        <span>
                                            {x.project_id}
                                        </span>
                                        <span>
                                            {x.project_name}
                                        </span>
                                        <span>
                                        {x.status.slice(0, 2)}
                                        </span>
                                        <input type={"checkbox"}
                                               disabled={x.status !== "FINISHED"}
                                               onChange={e => {
                                                   if (e.target.checked) {
                                                       setVisibleProjects([...visibleProjects, x.project_id]);
                                                   } else {
                                                       setVisibleProjects(visibleProjects.filter(t => t !== x.project_id));

                                                       viewer.entities.remove(projectViewData[x.project_id].floorPolygon);

                                                       for (let model of projectViewData[x.project_id].models) {
                                                           viewer.scene.primitives.remove(model);
                                                       }

                                                       delete projectViewData[x.project_id];
                                                       setProjectViewData({
                                                           ...projectViewData,
                                                       })
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div id={"cesiumContainer"} style={{width: 'calc(100vw - 300px)', height: '100vh'}}>

            </div>
        </div>
    )
}

export default GlobalServiceTest;
