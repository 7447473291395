import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import './App.css';

import AWS, { S3, DynamoDB, Lambda, StepFunctions } from 'aws-sdk';
import { Component } from 'react';
import Signin from './Signin';
import OffiProject from './OffiProject';

import OffiProjectEmailer from './OffiProjectEmailer';
import Root from './Root';

import jwt from 'jsonwebtoken';
import DynamodbJsonConverter from './DynamodbJsonConverter';
import BuilditProjectJson from './BuilditProjectJson';
import TMtoLLConverter from './TMtoLLConverter';
import LLtoTMConverter from './LLtoTMConverter';
import WktToObj from './WktToObj';
import DownloadBim from './DownloadBim';
import OffiProjectExcel from './OffiProjectExcel';
import OffiProjectDocx from './OffiProjectDocx';
import YouthHousingReport from './YouthHousingReport';
import ResultJsonDownload from './ResultJsonDownload';
import JomangDataset from "./JomangDataset";
import JomangResult from "./JomangResult";
import CesiumViewer from "./CesiumViewer";
import GlobalServiceTest from "./GlobalServiceTest";
import LhMapComputeReportScore from "./LhMapComputeReportScore";
import GlobalServiceGoogleMaps from "./GlobalServiceGoogleMaps";
import DslTest from "./DslTest";

// const GOOGLE_CERTS = {
//   "df3758908b792293ad977a0b991d98a77f4eeecd": "-----BEGIN CERTIFICATE-----\nMIIDJjCCAg6gAwIBAgIIdIvpxADOygkwDQYJKoZIhvcNAQEFBQAwNjE0MDIGA1UE\nAxMrZmVkZXJhdGVkLXNpZ25vbi5zeXN0ZW0uZ3NlcnZpY2VhY2NvdW50LmNvbTAe\nFw0xOTA3MjMxNDQ5MjlaFw0xOTA4MDkwMzA0MjlaMDYxNDAyBgNVBAMTK2ZlZGVy\nYXRlZC1zaWdub24uc3lzdGVtLmdzZXJ2aWNlYWNjb3VudC5jb20wggEiMA0GCSqG\nSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCiv9W85a2sdUJfsPD/2Aywu7RNe1YLWXTS\nZg2hM3YfnZzDeGrmSmMkestwKOxu8il2ikSLHfEbNIPdevKgdsdSLmyqTa14SGD8\naQ1ZwxSa2N3748p+jC+J1+up2L+D1gWwf2SBfN3Bx8DXR+3SVZzokVfLcIaGCqCz\njP+p/nh9NZmeIyOfioTQ8anMjC5tWGzXdvfWqUcqhG4nxJ7zacwEawZkYVNTkZja\nBuSE+AVj6QiS0H5dXa/KLt9aE73AUvivfQOJVvBrfMZY1q/+bO0ZQeVqIxoGPPfn\n5HFd0bO9ixVgkhRq8FQKqoeFynD9dLT8S3Q+VSjq9AKXRj8ReZ/pAgMBAAGjODA2\nMAwGA1UdEwEB/wQCMAAwDgYDVR0PAQH/BAQDAgeAMBYGA1UdJQEB/wQMMAoGCCsG\nAQUFBwMCMA0GCSqGSIb3DQEBBQUAA4IBAQBh+3OhBoXMYpdLnWj/e3/iI5jLBeYb\nle31LORs5fiy3T8Ox2s63PUwJNBctz/ngO3Hq+9HPQJCesLYLZstWxWJZ3Zxja9W\nmiE+euaeDno9Lkeb6NKV49+IPsUxQy79jSzmAbo4+d6DjliWZZ9P3Etu2i3O/M19\n+DIhyH6UXT7KildpaRbjfweQLQEoH5cowMAAjWNe4Uya4scCDnIBOu3mmbrzvfUH\nIO/jLBHrl385Zpz/Ls/JhAFG9d1ARGl8W5Gw+YePfmft7tVdzpWIHpVTuB3sQV9d\n4mSV3y0XFx9f6Yy6CWMKEYlZla0vXMFJGZkf5amrh0BxPe2DgM3xLpOK\n-----END CERTIFICATE-----\n",
//   "84f294c45160088d079fee68138f52133d3e228c": "-----BEGIN CERTIFICATE-----\nMIIDJjCCAg6gAwIBAgIITCoOy/eZD4cwDQYJKoZIhvcNAQEFBQAwNjE0MDIGA1UE\nAxMrZmVkZXJhdGVkLXNpZ25vbi5zeXN0ZW0uZ3NlcnZpY2VhY2NvdW50LmNvbTAe\nFw0xOTA3MTUxNDQ5MjhaFw0xOTA4MDEwMzA0MjhaMDYxNDAyBgNVBAMTK2ZlZGVy\nYXRlZC1zaWdub24uc3lzdGVtLmdzZXJ2aWNlYWNjb3VudC5jb20wggEiMA0GCSqG\nSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCLLOP3CkMNksJuBuvbMq/duzzOqN+VxUR3\ns9gyMoZimhpjYt2p+MXGvBOp30Rribc0hHaCI7H0ktCG2qpJKkEmkEGkeZoR3YUI\n/B3gZBMom6sQ/oej0dYC5FMyGzxbTil3/8dDQFzbYARqaJ4Yu3Nxh8hoBz2DlELF\nl1FVsYrRnS9I4DA3U8L9LvqdYamGi4e6RTjQSLCOoL4P0bbFgVso1vT80kzEnlQJ\n6c3zkWMSKLn6hbTAIGa3/VKvvpD97Qg3F8a7p1F/S8Zc++rGQg3PaSeOX15zZK84\nraGeZE3g73NOgofvT8MGuxkTR48NJxM5Y8clTbA7ITqDOQvoV1oVAgMBAAGjODA2\nMAwGA1UdEwEB/wQCMAAwDgYDVR0PAQH/BAQDAgeAMBYGA1UdJQEB/wQMMAoGCCsG\nAQUFBwMCMA0GCSqGSIb3DQEBBQUAA4IBAQACYQiRE/bUOPXX8p2SiRpCr2kI4bDI\ntomPJLIA99vOthX/Y6/l+AUYZuLhg3/6OBns5gKumDh4kRlmAyn4mZVyZlunOknD\nyKx8wHXXGNPZyKZ77ynO63i8oNd7aNQnarUEYkEt4I4lsDAP35JqC1no6omv67Up\nIysEY5XUmmgzch+hooaY+e6tIzCgBJWSdVyrJHFvVPLym4tNkw4H6tFOacuH5TM7\n1XVS5ktqwHQpiJqadJOQxPaOAsQ2vD6QWSrGd9EUwFa+w/z+VjC33mph1zEcjPNX\n86fvbCrV7arqw7RHez6g1/IUbOstro9LFZyN2ZLpfqthzmeXwsH4eGqa\n-----END CERTIFICATE-----\n"
// };

// function getPublicKey(header: jwt.JwtHeader, callback: jwt.SigningKeyCallback) {
//   //@ts-ignore
//   callback(null, GOOGLE_CERTS[header.kid]);
// }

interface AppState {
  initialized: boolean;
}

export default class App extends Component<{}, AppState> {
  static s3: S3;
  static ddb: DynamoDB.DocumentClient;
  static lambda: Lambda;

  static user: any;

  state = {
    initialized: false,
  } as AppState;

  componentWillMount = async () => {
    // @ts-ignore
    window.onUpdateIdToken = async (id_token: string) => {
      AWS.config.httpOptions = AWS.config.httpOptions || {};
      AWS.config.httpOptions!.timeout = 900000;
      AWS.config.httpOptions!.connectTimeout = 900000;
      AWS.config.region = 'ap-northeast-2';
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'ap-northeast-2:86cf7ea5-5d75-4084-9e60-427cf948134c',
        Logins: {
          'accounts.google.com': id_token as string,
        },
      });

      App.s3 = new AWS.S3();
      App.ddb = new AWS.DynamoDB.DocumentClient();
      App.lambda = new AWS.Lambda();

      App.user = jwt.decode(id_token);

      this.setState({
        initialized: true,
      });
    }

    if (window.location.pathname !== '/signin' && !window.sessionStorage.getItem("id_token")) {
      window.sessionStorage.setItem("login_redirect_uri", window.location.pathname);
      window.location.href = '/login.html';
    }
  }

  render = () => {
    if (!this.state.initialized) {
      return (
        <Router>
          <Route path="/" exact component={Root}/>
          <Route path="/signin" exact component={Signin} />
        </Router>
      );
    } else {
      return (
        <Router>
          <Route path="/" exact component={Root}/>
          <Route path="/signin" exact component={Signin} />
          <Route path="/offiproject" exact component={OffiProject} />
          <Route path="/offiproject-emailer" exact component={OffiProjectEmailer} />
          <Route path="/dynamodb-json-converter" exact component={DynamodbJsonConverter} />
          <Route path="/buildit-project-json" exact component={BuilditProjectJson} />
          <Route path="/tm-to-ll-converter" exact component={TMtoLLConverter}/>
          <Route path="/ll-to-to-converter" exact component={LLtoTMConverter}/>
          <Route path="/wkt-to-obj" exact component={WktToObj}/>
          <Route path="/download-bim" exact component={DownloadBim}/>
          <Route path="/offiproject-excel" exact component={OffiProjectExcel}/>
          <Route path="/offiproject-docx" exact component={OffiProjectDocx}/>
          <Route path="/youthhousing-report" exact component={YouthHousingReport}/>
          <Route path="/reslt-json-download" exact component={ResultJsonDownload}/>
          <Route path="/jomang-dataset" exact component={JomangDataset}/>
          <Route path="/jomang-result" exact component={JomangResult}/>
          <Route path="/cesium-viewer" exact component={CesiumViewer}/>
          <Route path="/global-service-test" exact component={GlobalServiceTest}/>
          <Route path="/global-service-google-maps" exact component={GlobalServiceGoogleMaps}/>
          <Route path="/lhmap-compute-report-score" exact component={LhMapComputeReportScore}/>
          <Route path="/dsl-test" exact component={DslTest}/>
        </Router>
      );
    }
  }
}
