import React, {useEffect, useState} from 'react';

import './App.css';
import App from "./App";

let list = null;

function JomangDataset() {
    let [currentId, setCurrentId] = useState();

    async function getNextImage() {
        if (!list) {
            const r = await App.s3.getObject({
                Bucket: 'teneleven-rnd-data',
                Key: 'viewdata_images/list.txt',
            }).promise();

            list = r.Body.toString("utf-8").split("\n").map(x => x.trim());
        }

        setCurrentId(list[Math.floor(Math.random() * list.length)]);
    }

    async function labelImage(imageId, value) {
        await App.ddb.put({
            TableName: 'rnd-viewdata-log',
            Item: {
                user_id: App.user.email,
                timestamp: new Date().toISOString(),
                image_id: imageId,
                value,
            },
        }).promise();
    }

    useEffect(() => {
        getNextImage();
    }, []);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ddd',
        }}>
          
          {/* <div style={{
                width: '98%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',                
            }}
          >
              
            <div 
              style={{
                position: 'relative',
                margin: '0 auto',
                width: '256px',
                height: '320px',
              }}
            >
              <img
                alt="1점"
                style={{                        
                    width: '256px',
                    height: '256px',
                }}
                src="/assets/1점.png"
              />
              <h4 style={{
                    width: '256px',
                    height: '25px',
                    textAlign: 'center'
                }}
              >
                <span>1점</span>
              </h4>
            </div>
            <div 
              style={{
                position: 'relative',
                margin: '0 auto',
                width: '256px',
                height: '320px',
              }}
            >
              <img
                alt="2점"
                style={{                        
                    width: '256px',
                    height: '256px',
                }}
                src="/assets/2점.png"
              />
              <h4 style={{
                    width: '256px',
                    height: '25px',
                    textAlign: 'center'
                }}
              >
                <span>2점</span>
              </h4>
            </div>
            <div 
              style={{
                position: 'relative',
                margin: '0 auto',
                width: '256px',
                height: '320px',
              }}
            >
              <img
                alt="3점"
                style={{                        
                    width: '256px',
                    height: '256px',
                }}
                src="/assets/3점.png"
              />
              <h4 style={{
                    width: '256px',
                    height: '25px',
                    textAlign: 'center'
                }}
              >
                <span>3점</span>
              </h4>
            </div>
            <div 
              style={{
                position: 'relative',
                margin: '0 auto',
                width: '256px',
                height: '320px',
              }}
            >
              <img
                alt="4점"
                style={{                        
                    width: '256px',
                    height: '256px',
                }}
                src="/assets/4점.png"
              />
              <h4 style={{
                    width: '256px',
                    height: '25px',
                    textAlign: 'center'
                }}
              >
                <span>4점</span>
              </h4>
            </div>
            <div 
              style={{
                position: 'relative',
                margin: '0 auto',
                width: '256px',
                height: '320px',
              }}
            >
              <img
                alt="5점"
                style={{                        
                    width: '256px',
                    height: '256px',
                }}
                src="/assets/5점.png"
              />
              <h4 style={{
                    width: '256px',
                    height: '25px',
                    textAlign: 'center'
                }}
              >
                <span>5점</span>
              </h4>
            </div>
            
          </div> */}
              

            <div style={{
                width: '100%',
                height: 'calc(100% - 100px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img
                    style={{
                        width: '60vh',
                        height: '60vh',
                    }}
                    src={App.s3.getSignedUrl('getObject', {
                        Bucket: 'teneleven-rnd-data',
                        Key: `viewdata_images/${currentId}`,
                    })}
                />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '100px',
                justifyContent: 'space-between',
            }}>
                <button
                    style={{flexGrow: '1', margin: '5px', fontSize: '20pt',}}
                    onClick={async () => {
                        await labelImage(currentId, 1);
                        await getNextImage()
                    }}
                >1
                </button>
                <button
                    style={{flexGrow: '1', margin: '5px', fontSize: '20pt',}}
                    onClick={async () => {
                        await labelImage(currentId, 2);
                        await getNextImage()
                    }}
                >2
                </button>
                <button
                    style={{flexGrow: '1', margin: '5px', fontSize: '20pt',}}
                    onClick={async () => {
                        await labelImage(currentId, 3);
                        await getNextImage()
                    }}
                >3
                </button>
                <button
                    style={{flexGrow: '1', margin: '5px', fontSize: '20pt',}}
                    onClick={async () => {
                        await labelImage(currentId, 4);
                        await getNextImage()
                    }}
                >4
                </button>
                <button
                    style={{flexGrow: '1', margin: '5px', fontSize: '20pt',}}
                    onClick={async () => {
                        await labelImage(currentId, 5);
                        await getNextImage()
                    }}
                >5
                </button>
            </div>
        </div>
    )
}

export default JomangDataset;
