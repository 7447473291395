import React from 'react';

import './App.css';

import { Component } from 'react';
import { RouteProps } from 'react-router';

import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';

import { Actions, jsonformsReducer } from '@jsonforms/core';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import App from './App';

const schema = {
  type: "object",
  properties: {
    "프로젝트 번호": {
      type: "number",
    },
    "담당자 이메일": {
      type: "string",
    },
  },
  required: ["프로젝트 번호", "담당자 이메일"],
};

const store = createStore(
  combineReducers(
     { jsonforms: jsonformsReducer() }),  
     // @ts-ignore
     {
       jsonforms: {
         cells: materialCells,
         renderers: materialRenderers,
       }
     }
);

interface Props {
  
}

interface State {
  data: any;
  output: string;
}

export default class extends Component<Props & RouteProps, State> {
  state = {
    data: {},
    output: "",
  };

  componentWillMount = () => {
    store.subscribe(this.onChange);

    store.dispatch(Actions.init({
      "담당자 이메일": App.user.email,
    }, schema));
  }

  onChange = () => {
    this.setState({
      data: store.getState().jsonforms.core!.data,
    });
  }

  onSubmit = async () => {
    const payload = {
      'stage': 'test',
      // @ts-ignore
      'pid': this.state.data['프로젝트 번호'],
      // @ts-ignore
      'email': this.state.data['담당자 이메일'],
    };

    this.setState({
      output: `Submitting...\n${JSON.stringify(payload, null, 2)}`,
    })
    const r = await App.lambda.invoke({
      FunctionName: 'platform-offiproject-emailer',
      Qualifier: 'v20190726',
      Payload: JSON.stringify(payload),
    }).promise();
    
    this.setState({
      // @ts-ignore
      output: JSON.stringify(JSON.parse(r.Payload), null, 2),
    });
  }

  render = () => {
    return (
      <Provider store={store}>
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '900px', marginTop: '100px' }}>
            <JsonForms />
            <input type="submit" style={{width: '100%', marginTop: '50px', height: '50px'}} onClick={this.onSubmit} />
            <pre style={{ marginTop: '30px', backgroundColor: 'grey', color: 'white' }}>
              {this.state.output}
            </pre>
          </div>
        </div>
      </Provider>
    )
  }
}
