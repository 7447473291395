import React, {useEffect, useState} from 'react';

import './App.css';
import App from "./App";

function LhMapComputeReportScore() {
    let [content, setContent] = useState("");
    let [addr, setAddr] = useState();
    let [addrEnabled, setAddrEnabled] = useState(true);

    async function compute(addr) {
        setAddrEnabled(false);
        setContent("연산중입니다 잠시만 기다려주세요");

        try {
            const r = await App.lambda.invoke({
                FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:rnd-team-stack-dev-EsProxy",
                Payload: JSON.stringify({
                    table: "parcel-info",
                    body: {
                        query: {
                            query_string: {
                                query: `주소:"${addr}"`,
                            },
                        },
                    },
                }),
            }).promise();

            const rr = JSON.parse(r.Payload);

            if (rr.hits.total < 1) {
                throw new Error("PNU를 찾을 수 없습니다");
            }

            const pi = rr.hits.hits[0]._source;
            const pnu = pi.pnu;

            const rrr = await App.lambda.invoke({
                FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:rnd-team-stack-dev-LhMapComputeReportScore",
                Payload: JSON.stringify({
                    pnu,
                }),
            }).promise();

            const rrrr = JSON.parse(rrr.Payload);

            console.log(rrrr);

            const score = rrrr.result;

            setContent(`\
주소: ${pi.주소}
확장성 점수 : ${score.임대관리용이성.score}
생활편익 점수 : ${score.생활편익.score}
용도지역 점수 : ${score.용도지역.score}
대중교통 nearest : ${JSON.stringify(score.대중교통_접근성.nearest, null, 2)}
대중교통 점수 : ${score.대중교통_접근성.score}
대학교 nearest: ${JSON.stringify(score.대학교_접근성.nearest, null, 2)}
대학교 점수: ${score.대학교_접근성.score}

JSON:
${JSON.stringify(score, null, 2)}
        `);
        } catch (e) {
            setContent(`\
에러발생:
${e.toString()}
            `);
        }

        setAddrEnabled(true);
    }

    return (
        <div style={{"display": "flex", "flexDirection": "column", "alignItems": "center"}}>
            <div style={{
                "width": "90vw",
                "padding": "10px",
                "display": "flex",
                "flexDirection": "column",
                "alignItems": "center"
            }}>
                <input
                    type={"text"}
                    placeholder={"주소 입력"}
                    style={{"width": "50%"}}
                    value={addr}
                    onChange={e => {
                        setAddr(e.target.value)
                    }}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            compute(addr);
                        }
                    }}
                    disabled={!addrEnabled}
                />
            </div>
            <div style={{
                "border": "1px solid",
                "width": "90vw",
                "display": "flex",
                "alignItems": "center",
                "flexDirection": "column"
            }}>
                <pre style={{"width": "100%", "height": "100%"}}>
                    {content}
                </pre>
            </div>
        </div>
    );
}

export default LhMapComputeReportScore;
