import React, {useEffect, useState} from 'react';

import './App.css';
import App from "./App";

function JomangResult() {
    let [list, setList] = useState();
    let [currentId, setCurrentId] = useState();

    useEffect(() => {
        (async () => {
            const r = await App.s3.listObjectsV2({
                Bucket: 'teneleven-rnd-models',
                Prefix: 'jomang/',
                Delimiter: '/',
                MaxKeys: 1000,
            }).promise();

            const list = r.CommonPrefixes.map(x => x.Prefix.split('/').slice(-2, -1)[0]).reverse();

            setList(list);
            setCurrentId(list[0])
        })();
    }, []);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#ddd',
        }}>
            <div
                style={{
                    "width": "200px",
                    "backgroundColor": "#fff",
                    "overflow-y": "scroll",
                }}
            >
                {
                    list && list.map(x => {
                        return (
                            <button
                                style={{
                                    width: '100%',
                                    height: '25px',
                                    margin: '5px 0px',
                                    color: (x === currentId) ? '#f00' : '#000',
                                }}
                                onClick={e => {
                                    setCurrentId(x);
                                }}
                            >
                                {new Date(Date.parse(x)).toLocaleString()}
                            </button>
                        )
                    })
                }
            </div>
            <div style={{
                width: 'calc(100% - 200px)',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {
                    currentId && (
                        <img
                            style={{
                                width: '100vh',
                                height: '100vh',
                            }}
                            src={App.s3.getSignedUrl('getObject', {
                                Bucket: 'teneleven-rnd-models',
                                Key: `jomang/${currentId}/output.png`,
                            })}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default JomangResult;
